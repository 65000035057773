<template>
  <vs-row>
    <vs-col vs-w="8" vs-lg="8" vs-sm="12" vs-xs="12" class="m-0">
      <div class="req-temp-form payment-request-form">
        <form @submit.prevent="createTemplate" enctype="multipart/form-data">
          <vs-row id="profile-page">
            <!-- Request details -->
            <vs-card v-if="isLoaded" class="accordin-card mt-5">
              <vs-collapse accordion>
                <vs-collapse-item ref="requestDetails" :open="true">
                  <div slot="header">
                    <h3 class="text-left font-normal">Basic request info <span class="mid-blue">*</span></h3>
                  </div>

                  <div>
                    <vs-row class="items-start">
                      <vs-col vs-w="6">
                        <div class="input-grp mb-6 ml-0" ref="amount">
                          <label class="text-lg font-normal w-full">{{ isRecurring ? "Recurring amount" : (isTwoParty ? "Amount requested to fund" : "Amount requested for payment") }} <span class="mid-blue">*</span></label>
                          <money
                            class="moneyPR"
                            v-model="amount"
                            name="amount"
                            :precision="2"
                            v-validate="rules"
                            v-bind="money2"
                            :placeholder="'Amount'"
                            id="amount"
                          />
                          <span class="text-danger text-sm" v-show="errors.has('amount')">{{ errors.first("amount") }}</span>
                          <span class="text-danger text-sm" v-if="showExtraConditionError">{{ errorMsg }}</span>
                        </div>

                        <div class="input-grp mb-6 mx-0">
                          <label class="text-lg font-normal w-full">Your reference <span class="mid-blue">*</span></label>
                          <vs-input class="w-full" v-validate="'required'" size="large" name="reference" id="reference" v-model="reference" />
                          <span class="text-danger text-sm" v-show="errors.has('reference')">{{ errors.first("reference") }}</span>
                        </div>

                        <vs-row v-if="productConfigType == 'THREE_PARTY' && (customTextData.allowOverride || customTextData.body !== '')">
                          <div class="input-grp mb-8">
                              <label class="label w-full">Personal message to payer</label>
                              <vs-textarea
                                counter="250"
                                maxlength="250"
                                v-if="customTextData.allowOverride"
                                class="custom-textarea custom-message"
                                v-model="customerData.customerMessage"
                                id="custom-textarea"
                                @input="auto_grow('custom-textarea')"
                              />
                              <p v-else>{{ customTextData.body }}</p>
                          </div>
                        </vs-row>
                      </vs-col>
                      <vs-col vs-w="6">
                        <div class="input-grp mb-4 mx-0">
                          <div v-if="!isRecurring && !showSummaryCard" class="text-base">
                            <div>Finance option</div>
                            <div>
                              <label v-if="hasPayNow" class="font-normal w-auto">Pay Now</label>
                              <label v-if="hasPayLater">
                                <span v-if="hasPayNow">&nbsp;/ </span>
                                <span v-if="productConfigType == 'THREE_PARTY'">{{ requestTemplate.product.productName }}</span>
                              </label><br />
                              <label v-if="requestTemplate.product">{{ requestTemplate.product.productDescription }}</label>
                            </div>
                          </div>
                          <p v-if="isRecurring" class="text-base">
                            <vs-row> Payment option </vs-row>
                            <vs-row>
                              <a @click="openPopUp('recurring')" class="mid-blue">Recurring</a>
                            </vs-row>
                          </p>
                        </div>
                        <div class="input-grp flex-col mb-4 md:mb-8 ml-0" v-if="reqTemplateId">
                          <p class="text-base mb-0">Request template</p>
                          <div>
                            <label class="text-lg font-normal mb-0 w-full">
                              {{ `${requestTemplate.templateName} (${requestTemplate.requestTemplateId})` }}
                            </label>
                          </div>
                        </div>

                        <attachment @updateFiles="fileUpdate" />
                      </vs-col>
                    </vs-row>
                  </div>

                  <!-- popup starts-->
                  <vs-popup id="holamundo" class="config-popup paymentSummaryPopup" :title="'Payment schedule'" :active.sync="schedulePopup">
                    <div class="w-full">
                      <vs-col vs-w="12" v-if="!isRecurring && activePlan.productType == 'B2C_BNPL'">
                        <vs-row class="flex justify-between pay-later-card lg:p-4 md:p-3 p-2 items-start">
                          <div class="flex flex-col justify-center items-center">
                            <img :src="update" alt="update" />
                            <span class="dark-blue text-xs mt-2"> Every</span>
                            <span class="dark-blue text-xs">{{ `1 ${weekOrMonthOrDay}` }}</span>
                          </div>
                          <div class="flex flex-col justify-center items-center">
                            <img :src="playCircle" alt="playcircle" />
                            <span class="dark-blue text-xs mt-2">Starts on</span>
                            <span class="dark-blue text-xs">acceptance date</span>
                          </div>
                          <div class="flex flex-col justify-center items-center">
                            <img :src="stopCircle" alt="stopcircle" />
                            <span class="dark-blue text-xs mt-2"> Ends after</span>
                            <span class="dark-blue text-xs">{{ `${activePlan.noOfPayments} payments` }}</span>
                          </div>
                        </vs-row>
                        <vs-table :data="paymentSchedules" class="payment-schedule-table">
                          <template slot="thead">
                            <vs-th class="text-base"> Payment date</vs-th>
                            <vs-th class="text-base"> Amount</vs-th>
                          </template>
                          <template slot-scope="{ data }">
                            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                              <vs-td :data="data[indextr].value">
                                {{ formatScheduleDate(data[indextr].value) }}
                              </vs-td>
                              <vs-td class="sec-row-payment" :data="data[indextr].label">
                                {{ data[indextr].label !== "0.00" ? `$ ${data[indextr].label}` : "-" }}
                              </vs-td>
                            </vs-tr>
                          </template>
                        </vs-table>
                        <div class="b2c">
                          <vs-row class="flex justify-between">
                            <span class="text-lg dark-blue">Total amount</span>
                            <span class="text-lg dark-blue">{{ this.amount ? `$ ${this.totalCustomerPayable}` : "-" }}</span>
                          </vs-row>
                        </div>
                      </vs-col>
                      <vs-col vs-w="12" v-if="!isRecurring && (activePlan.productType == 'B2C_BNPO' || activePlan.productType == 'B2C_BNPO_NC')">
                        <div class="gray mb-5 items-center flex">
                          <img :src="calenderImage" alt="calenderImage" />
                          <p class="dark-blue text-base font-normal ml-4">
                            {{ activePlan.productText }}
                          </p>
                        </div>
                        <div class="b2c">
                          <vs-row class="flex justify-between">
                            <span class="text-lg dark-blue">Customer pays</span>
                            <span class="text-lg amount">{{ this.totalCustomerPayable ? `$ ${addZeroes(this.totalCustomerPayable)}` : "-" }}</span>
                          </vs-row>
                          <vs-row class="mt-4">
                            <span class="text-lg dark-blue">Merchant receives</span>
                            <span class="text-lg amount">{{ merchantAmount ? `$ ${addZeroes(merchantAmount)}` : "-" }}</span>
                          </vs-row>
                        </div>
                      </vs-col>
                      <vs-col vs-w="12" class="" v-if="isRecurring">
                        <vs-row class="flex justify-between pay-later-card lg:p-4 md:p-3 p-2 items-start">
                          <div class="flex flex-col justify-center items-center">
                            <img :src="update" alt="update" />
                            <div v-if="frequency.period">
                              <span class="dark-blue text-xs mt-2"> Every</span>
                              <span class="dark-blue text-xs">{{ `${` `} ${frequency.frequency} ${` `} ${frequency.period}` }}</span>
                            </div>
                            <div v-else>
                              <span class="dark-blue text-xs mt-2 text-center block">Please select payment options first</span>
                            </div>
                          </div>
                          <div class="flex flex-col justify-center items-center">
                            <img :src="playCircle" alt="playcircle" />
                            <div v-if="frequency.startDate">
                              <span class="dark-blue text-xs mt-2">Starts on </span>
                              <span class="dark-blue text-xs">{{ moment(frequency.startDate) }}</span>
                            </div>
                            <div v-else>
                              <span class="dark-blue text-xs mt-2 text-center block">Please select payment options first</span>
                            </div>
                          </div>
                          <div class="flex flex-col justify-center items-center">
                            <img :src="stopCircle" alt="stopcircle" />
                            <div v-if="frequency.endType">
                              <span class="dark-blue text-xs mt-2">Ends </span>
                              <span class="dark-blue text-xs mt-2" v-if="frequency.endType == 'after'">after {{ frequency.endPayments }} payments</span>
                              <span v-if="frequency.endType == 'by'" class="dark-blue text-xs mt-2">on {{ moment(frequency.endDate) }}</span>
                            </div>
                            <div v-else>
                              <span class="dark-blue text-xs mt-2 text-center block">Please select payment options first</span>
                            </div>
                          </div>
                        </vs-row>
                        <div class="vs-table--content">
                          <vs-row class="flex justify-between">
                            <span class="text-lg dark-blue">Recurring amount</span>
                            <span class="text-lg dark-blue" v-if="amount">$ {{ addZeroes(amount ? amount : "-") }}</span>
                            <span v-else>-</span>
                          </vs-row>
                          <vs-row class="flex justify-between mt-4">
                            <span class="text-lg dark-blue">Total amount</span>
                            <span class="text-lg dark-blue" v-if="amount">$ {{ addZeroes(amount ? amount : "-") }}</span>
                            <span v-else>-</span>
                          </vs-row>
                        </div>
                      </vs-col>
                    </div>
                  </vs-popup>
                  <!-- popup ends -->
                </vs-collapse-item>
              </vs-collapse>
            </vs-card>

            <!-- payment options for recurring  -->
            <vs-card class="accordin-card mt-5 rec-overflow" v-if="isRecurring" @click="active = !active" :class="{ nooverf: active }">
              <vs-collapse accordion>
                <vs-collapse-item ref="paymentOptions" :open="true">
                  <div slot="header">
                    <h3 class="text-left font-normal">
                      Payment settings <span class="mid-blue text-base">*</span>
                    </h3>
                  </div>

                  <template>
                    <vs-row class="mb-5">
                      <vs-col vs-w="12">
                        <p class="font-normal">Configure your recurring payment settings.</p>
                      </vs-col>
                    </vs-row>
                    <vs-row class="flex-col mb-8">
                      <div>
                        <label class="font-normal text-base p-0 m-0 w-full">Starts</label>
                      </div>
                      <div>
                        <date-picker
                          v-validate="'required'"
                          name="startDate"
                          id="startDate"
                          v-model="frequency.startDate"
                          lang="en"
                          type="date"
                          placeholder="DD/MM/YYYY"
                          class="w-auto"
                          format="YYYY/MM/DD"
                          :disabled-days="(date) => date <= new Date()"
                        ></date-picker>
                        <span class="text-danger text-sm" v-show="errors.has('startDate')">{{ errors.first("startDate") }}</span>
                      </div>
                    </vs-row>

                    <vs-row class="flex-col mb-8">
                      <div>
                        <label class="font-normal w-full text-base">Repeat every</label>
                      </div>
                      <div class="flex">
                        <div class="mr-8 force-small-input">
                          <vs-input v-validate="'required'" id="interval" name="interval" v-model="frequency.frequency" />
                          <span class="text-danger text-sm" v-show="errors.has('interval')">{{ errors.first("interval") }}</span>
                        </div>
                        <div>
                          <vs-select v-validate="'required'" name="everySelect" id="everySelect" placeholder="Weeks" class="payment-setting-select" v-model="frequency.period" size="small">
                            <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item, index) in everyOptions" />
                          </vs-select>
                          <span class="text-danger text-sm" v-show="errors.has('everySelect')">{{ errors.first("everySelect") }}</span>
                        </div>
                      </div>
                    </vs-row>

                    <vs-row class="my-8 flex-col">
                      <div class="mb-4">
                        <label class="font-normal text-base p-0 m-0">Ends</label>
                      </div>
                      <div class="flex items-center">
                        <div class="mr-16">
                          <vs-radio v-model="frequency.endType" v-validate="'required'" vs-name="endType" id="endType" name="endType" vs-value="by" class="flex">
                            <span class="block" style="color: #05053f; width: 50px">On</span>
                          </vs-radio>
                        </div>
                        <div>
                          <date-picker
                            name="endDate"
                            id="endDate"
                            v-model="frequency.endDate"
                            lang="en"
                            type="date"
                            placeholder="DD/MM/YYYY"
                            class="w-auto"
                            format="YYYY/MM/DD"
                            :disabled-days="(date) => date <= new Date()"
                          ></date-picker>
                        </div>
                      </div>
                    </vs-row>
                    <vs-row class="flex items-center">
                      <div class="mr-16">
                        <vs-radio v-model="frequency.endType" vs-name="endType" name="endType" vs-value="after" class="flex">
                          <span class="block" style="color: #05053f; width: 50px">After</span>
                        </vs-radio>
                      </div>
                      <div class="flex items-end">
                        <vs-input class="payments-width-force" v-model="frequency.endPayments" /><span class="ml-2">payments</span>
                      </div>
                    </vs-row>
                    <vs-row>
                      <div>
                        <span class="text-danger text-sm" v-show="errors.has('endType')">{{ errors.first("endType") }}</span>
                      </div>
                    </vs-row>
                  </template>
                </vs-collapse-item>
              </vs-collapse>
            </vs-card>

            <!-- payment options for recurring ends here -->

            <!-- Disbursement account -->
            <vs-card class="accordin-card mt-5" v-if="isLoaded && !reqTemplateId">
              <vs-collapse accordion>
                <vs-collapse-item ref="disbursementAccount" :open="true">
                  <div slot="header">
                    <h3 class="text-left font-normal">
                      Disbursement account <span class="mid-blue" v-if="customerData.sendComms">*</span>
                    </h3>
                  </div>
                  <label class="w-full text-base font-light">Select a bank account to receive payments into</label>
                  <div class="input-grp mt-2">
                    <vs-select v-model="bankId" name="bankId" id="bankId" class="w-full selectExample" v-validate="'required'" :placeholder="'Select account'" @change="setRemittance">
                      <vs-select-item :key="index" :value="item.bankId" :text="item.displayName ? item.displayName : item.accountName" v-for="(item, index) in bankList" />
                    </vs-select>
                    <span class="text-danger text-sm" v-show="errors.has('bankId')">{{ errors.first("bankId") }}</span>
                  </div>
                </vs-collapse-item>
              </vs-collapse>
            </vs-card>

            <!-- Customer Details -->
            <vs-card class="accordin-card" v-if="productConfigType == 'THREE_PARTY'">
              <vs-collapse accordion>
                <vs-collapse-item ref="customerDetails" :open="true">
                  <div slot="header">
                    <h3 class="text-left font-normal">
                      Customer details <span class="mid-blue" v-if="customerData.sendComms">*</span>
                    </h3>
                  </div>
                  <customer-details :customerData="customerData" :isSubmitted="isSubmitted" @checkForm="checkForm" ref="customerFields"></customer-details>
                </vs-collapse-item>
              </vs-collapse>
            </vs-card>
            <!-- custom fields -->
            <vs-card class="accordin-card" v-if="additionalBind && (productCustomFields.length > 0 || customFieldsArray.length > 0)">
              <vs-collapse accordion>
                <vs-collapse-item :open="!isCustomFieldEmpty || !isProductFieldEmpty" ref="customCollapse">
                  <div slot="header">
                    <h3 class="text-left font-normal">Additional information <span v-if="hasRequiredInformation" class="mid-blue">*</span></h3>
                  </div>
                  <div>
                    <div v-if="!isProductFieldEmpty">
                      <p class="lg:ml-6">This information is required for us to approve a request using {{ requestTemplate.product ? requestTemplate.product.productName : "" }}</p>
                      <vs-row class="lg:ml-6">
                        <product-fields
                          :productCustomFields="productCustomFields"
                          :isSubmitted="isSubmitted"
                          :productFieldErrors="productFieldErrors"
                          @maxPercentageError="maxPercentageError"
                          @checkForm="checkForm"
                          v-if="productCustomFields.length > 0"
                          ref="productFields"
                        />
                      </vs-row>
                    </div>
                    <div v-if="!isCustomFieldEmpty && reqTemplateId">
                      <h4 class="text-lg mt-4">Custom Fields</h4>
                      <hr class="line-hr mb-6 mt-4" />
                      <p>This is information your business wants to collect relating to this request.</p>
                      <vs-row class="lg:ml-6">
                        <custom-fields @fileRemoved="changeCustomFieldArray" :customFieldsArray="customFieldsArray" :isSubmitted="isSubmitted" @checkForm="checkForm" v-if="customFieldsArray.length > 0" ref="customFields"></custom-fields>
                      </vs-row>
                    </div>
                  </div>
                </vs-collapse-item>
              </vs-collapse>
            </vs-card>

            <!-- Disbursement -->
            <disbursement
              v-if="disbursementBind"
              :disbursement="requestTemplate.disbursement"
              :linked-account="requestTemplate.linkedAccount"
              :ref-model="requestTemplate.refModel"
              :isSubmitted="isSubmitted"
              :banks="disbursementBanks"
              :selected-account-details="selectedAccount"
              :show-disbursement-terms="showDisbursementTerms"
              :selected-plan="selectedPlan"
              :merchant-disbursement-reference="merchantDisbursementReference"
              @changeLinkedAccount="changeLinkedAccount"
              @setDisbursement="setDisbursement"
              @updateDisbursementTerms="changeDisbursementTerms"
              @handleChangeDisbursementReference="handleChangeDisbursementReference"
              @handleDisbursementDate="handleDisbursementDate"
            />

            <!-- Debit -->
            <debit
              v-if="displayDebit"
              :from="'request-form'"
              :fullTerms="allTerms"
              :autoDebit="autoDebit"
              :debit="debit"
              :isSubmitted="isSubmitted"
              :banks="bankList"
              @changeDebitAccount="changeDebitAccount"
              @on="termsCallBack"
              @handleChangeDebitReference="handleChangeDebitReference"
            />

            <!-- Notification -->
            <vx-card class="accordin-card" v-if="reqTemplateId">
              <vs-collapse accordion>
                <vs-collapse-item ref="notification">
                  <div slot="header">
                    <h3 class="text-left font-normal">Communications</h3>
                  </div>
                  <div class="grid grid-cols-2 gap-16">
                    <div>
                      <h4>Notifications</h4>
                      <hr class="my-2">
                      <p>Notifications on status changes for this request will be sent to the address/es below</p>
                      <Notification
                        :notification="this.requestTemplate.notifications"
                        :userDetail="user"
                        :notificationEmail="notificationEmail"
                        :defaultNotificationEmails="defaultNotificationEmails"
                        @checkForm="checkForm"
                        @setNotificationEmail="setNotificationEmail"
                        v-if="notificationBind"
                      />
                    </div>
                    <div v-if="reqTemplateId && displayRemittance">
                      <h4 class="text-left font-normal">Remittance advice</h4>
                      <hr class="my-2">
                      <p>A remittance advice (for disbursements from us to the nominated account) will be sent to the address/es below</p>
                      <remittance-advice v-if="remittanceBind" :remittance-advice="defaultRemittanceAdvice" :isSubmitted="isSubmitted" @checkForm="checkForm" />
                    </div>
                  </div>
                </vs-collapse-item>
              </vs-collapse>
            </vx-card>

            <Terms
              v-if="showTermsCard"
              :from="'request-form'"
              :fullTerms="allTerms"
              class="custom-terms"
              ref="termsValidation"
              :termsDetail.sync="termsDetail"
              :autoDebit.sync="autoDebit"
              @on="termsCallBack"
              @checkForm="checkForm"
            />
          </vs-row>
          <leave-popup :popup-active="popupActive" @handleClick="popActions"></leave-popup>
          <pop-up :errorsList="errors" form-name="paymentRequestForm"></pop-up>
          <vs-row>
            <div class="actions mt-5 text-right">
              <vs-button class="btn btn-primary lg:px-20 md:px-20 sm:px-10" v-round @click="sendPR" :disabled="disableCreateButton">Create</vs-button>
              <span color="danger" @click="onCancel" class="btn pl-5 pr-5 ml-5 edit-profile" v-round>Cancel</span>
            </div>
          </vs-row>
        </form>
      </div>
    </vs-col>
    <vs-col v-if="(showSummaryCard && reqTemplateId) || displayExplainerSection" vs-w="4" vs-lg="4" vs-md="4" vs-sm="12" vs-xs="12" class="m-0 mob-view pl-0 md:pl-3 lg:pl-3">
      <div v-if="(showSummaryCard && reqTemplateId)">
        <payment-summary :amount="amount" :transaction-schedule="transactionSchedule" :payment-page-setting="paymentPageSetting" :selected-plan="selectedPlan" ref="summary" />
      </div>
      <Explainer v-if="displayExplainerSection" :explainer="requestTemplate.product.explainer" :productConfigType="productConfigType" />
    </vs-col>
  </vs-row>
</template>

<script>
import { mapActions } from "vuex";
import { Validator } from "vee-validate";
import { Money } from "v-money";
import { RRule } from "rrule";
import moment from "moment";
import _ from "lodash";

import DatePicker from "vue2-datepicker";
const update = require("@/assets/images/update.png");
const playCircle = require("@/assets/images/play-circle.png");
const stopCircle = require("@/assets/images/stop-circle.png");
const calenderImage = require("@/assets/images/calender.png");

import LeavePopup from "@/views/components/LeavePopup";
import PopUp from "@/views/components/ValidationPopup.vue";

import CustomFields from "./components/customFieldsInPR.vue";
import ProductFields from "./components/productFieldsInPR.vue";
import PaymentSummary from "./components/paymentSummary.vue";

import CustomerDetails from "./CustomerDetails.vue";
import Attachment from "./Attachment.vue";
import Notification from "./Notification.vue";
import RemittanceAdvice from "./RemittanceAdvice.vue";
import Disbursement from "./Disbursement.vue";
import { leavePopUpMixin } from "@/mixins/leave-pop-up";
import Terms from "./terms.vue";
import Debit from "./Debit.vue";
import Explainer from "./Explainer.vue";

const dictionary = {
  en: {
    attributes: {
      templateName: "template name",
      linkedAccount: "linked account",
    },
  },
};

Validator.localize(dictionary);

export default {
  mixins: [leavePopUpMixin],
  components: {
    Attachment,
    CustomerDetails,
    CustomFields,
    DatePicker,
    Debit,
    Disbursement,
    Explainer,
    Money,
    Notification,
    LeavePopup,
    PaymentSummary,
    ProductFields,
    PopUp,
    RemittanceAdvice,
    Terms,
  },

  data() {
    return {
      appName: process.env.VUE_APP_NAME == "FlipPay" ? "FlipPay" : "Rello",
      defaultReference: "",
      planDisplayName: "",
      totalCustomerPayable: "",
      merchantAmount: "",
      atLeastOneCForPFrequired: false,
      weekOrMonthOrDay: "",
      redirectData: {},
      customTextData: {},
      active: false,
      everyOptions: [
        { text: "Week", value: "week" },
        { text: "Month", value: "month" },
      ],
      frequency: {},
      isSaved: false,
      noFrequencyData: false,
      paymentSchedules: [],
      totalNumberOfPayments: 0,
      schedulePopup: false, // determines if the leave popup is to be shown
      showValidationPopUp: false, // determines if the leave popup is to be shown
      nextObj: "",
      addressData: "",
      planId: "",
      activePlan: {},
      payLaterSchedule: [],
      isProductFieldEmpty: false,
      isCustomFieldEmpty: false,
      defaultCustomFields: [],
      customFieldsArray: [],
      amount: "",
      money2: {
        precision: 2,
        prefix: "$ ",
      },
      reference: "",
      customFieldBind: "",
      popupActive: false,
      hasPayNow: "",
      hasPayLater: "",
      isRecurring: "",
      reqOptions: [],
      reqTemplateId: "",
      requestTemplate: {},
      saveTemplate: "save",
      globalSettings: "",
      files: [],
      customerData: {
        customerName: "",
        customerEmail: "",
        customerMobile: "",
        customerMessage: "",
        sendComms: true,
      },
      isSubmitted: false,
      defaultProductCustomFields: [],
      productCustomFields: [],
      update: update,
      playCircle: playCircle,
      stopCircle: stopCircle,
      calenderImage: calenderImage,
      checkFormDirty: false,
      additionalBind: false,
      extraConditions: [],
      showExtraConditionError: false,
      errorMsg: "",
      notificationEmail: [],
      notificationBind: false,
      remittanceBind: false,
      templateHeader: {},
      gracePeriod: "",
      isLoaded: false,
      defaultNotificationEmails: [],
      showWarning: false,
      productConfigType: 'THREE_PARTY',
      termsDetail: {},
      showTermsCard: false,
      paymentMethodTerms: "",
      terms: "",
      privacy: "",
      productFieldErrors: [],
      paymentPageSetting: {},
      transactionSchedule: [],
      selectedPlan: "",
      showSummaryCard: false,
      autoDebit: false,
      bankList: [],
      bankId: "",
      disbursement: {},
      disbursementBind: false,
      disbursementTerms: false,
      manualDisbursement: {},
      savedExternalAccounts: [],
      selectedAccount: {},
      selectedLinkedAccount: {},
      showDisbursementTerms: false,
      defaultRemittanceAdvice: {},
      templateRemittanceAdvice: {},
      debit: {},
      disbursementReference: "",
      merchantDisbursementReference: "",
      allTerms: null,
      latestTerms: "",
      latestDirectDebitTerms: "",
      debitReference: null,
      disabledDisbursement: false,
      franchisorRemittanceEmail: [],
      allowDisbursementDateOverride: false,
      disbursementDate: ""
    };
  },

  computed: {
    displayExplainerSection () {
      let display = false;

      if (this.requestTemplate && this.requestTemplate.product && this.requestTemplate.product.explainer && this.requestTemplate.product.explainer.isEnabled) {
        display = true;
      }

      return display;
    },

    validateForm() {
      return !this.errors.any();
    },

    user() {
      return this.$store.state.AppActiveUser;
    },

    partnerId() {
      return this.user.userType === "admin" ? this.user._id : this.user.partnerId;
    },

    merchantId() {
      if (this.reqTemplateId) {
        return this.user.userType === "admin" ? this.user._id : this.user.partnerId;
      } else {
        return this.$route.params.partnerId;
      }
    },

    rules() {
      if (!this.hasPayNow && (parseFloat(this.activePlan.minimumAmount) > 0 || parseFloat(this.activePlan.maximumAmount) > 0)) {
        return "required|payLaterAmountField";
      } else {
        return "requiredAmount";
      }
    },

    hasRequiredInformation() {
      const hasRequiredInProductFields = this.productCustomFields && this.productCustomFields.length ? this.productCustomFields.some(item => item.reqToSendRequest) : false;
      const hasRequiredInCustomFields = this.customFieldsArray && this.customFieldsArray.length ? this.productCustomFields.some(item => item.reqToSendRequest) : false;

      return (hasRequiredInProductFields || hasRequiredInCustomFields) ? true : false;
    },

    disableCreateButton() {
      if (this.showTermsCard) {
        return !this.terms || (this.autoDebit ? !this.paymentMethodTerms : false);
      }

      return false;
    },

    disbursementBanks() {
      let banks = [];

      if (this.disbursement && this.disbursement.allowMerchantAccount) {
        const data = {
          title: "Your accounts",
          accounts: this.bankList
        };

        banks.push(data);
      } else if (this.disbursement && !this.disbursement.allowMerchantAccount && this.selectedAccount) {
        const findBank = this.bankList.find(item => item.bankId == this.selectedAccount._id);

        if (findBank) {
          const data = {
            title: "Your accounts",
            accounts: [findBank]
          };

          banks.push(data);
        }
      }

      if (this.disbursement && this.disbursement.allowExternalAccount && this.savedExternalAccounts && this.savedExternalAccounts.length) {
        const data = {
          title: "External accounts",
          accounts: this.savedExternalAccounts
        };

        banks.push(data);
      } else if (this.disbursement && !this.disbursement.allowExternalAccount && this.savedExternalAccounts && this.selectedAccount) {
        const findBank = this.savedExternalAccounts.find(item => item.bankId == this.selectedAccount._id);

        if (findBank) {
          const data = {
            title: "External accounts",
            accounts: [findBank]
          };

          banks.push(data);
        }
      }

      return banks;
    },

    displayRemittance() {
      let display = true;

      if (this.disbursement && this.disbursement.type && this.disbursement.type == "customer") {
        display = false;
      }

      return display;
    },

    displayDebit() {
      return this.isLoaded && this.reqTemplateId && this.selectedPlan && (this.selectedPlan.productConfigType == "TWO_PARTY") && this.selectedPlan.autoDebit && this.allTerms;
    },

    isTwoParty() {
      return (this.productConfigType == "TWO_PARTY");
    }
  },

  created() {
    this.reqTemplateId = this.$route.params.reqTemId;

    Validator.extend("payLaterAmountField", {
      getMessage: (_field) => {
        if (parseFloat(this.activePlan.minimumAmount) > 0 && this.amount < parseFloat(this.activePlan.minimumAmount)) {
          return "Amount must be above " + this.formatCurrency(this.activePlan.minimumAmount);
        } else if (parseFloat(this.activePlan.maximumAmount) > 0 && this.amount > parseFloat(this.activePlan.maximumAmount)) {
          return " Amount must be below " + this.formatCurrency(this.activePlan.maximumAmount);
        } else {
          return "Amount must be between " + this.formatCurrency(this.activePlan.minimumAmount) + " and " + this.formatCurrency(this.activePlan.maximumAmount);
        }
      },
      validate: (value) => value <= parseFloat(this.activePlan.maximumAmount) && value >= parseFloat(this.activePlan.minimumAmount),
    });

    Validator.extend("requiredAmount", {
      getMessage: (field) => "Amount field is required",
      validate: (value) => !!value,
    });

    Validator.extend("alphanumFormat", {
      getMessage: () => "Invalid account number",
      validate: (value) => value.match(/^[a-zA-Z0-9]+$/) !== null,
    });
  },

  methods: {
    ...mapActions("paymentRequest", ["calculateOnPayLater", "getPlanByMerchantAndCustomPlanId", "storeNewPaymentRequest"]),
    ...mapActions("paymentRequestTemplate", ["fetchRequestTemplateForRequestForm"]),
    ...mapActions("merchantGlobalSetting", ["fetchSettingsByMerchantId"]),
    ...mapActions("admin", ["checkLogin"]),
    ...mapActions("bank", ["fetchBankByPartnerId"]),
    ...mapActions("savedExternalAccount", ["fetchSavedExternalAccounts"]),
    ...mapActions("legals", ["fetchTermsAndConditions"]),

    setRemittance() {
      const bank = this.bankList.find(item => item.bankId == this.bankId);

      if (!bank) {
        return;
      }

      this.franchisorRemittanceEmail = (bank.remittanceAdviceEmail && bank.remittanceAdviceEmail.length) ? bank.remittanceAdviceEmail : [];
    },

    async getLegals() {
      this.$vs.loading();
      await this.fetchTermsAndConditions().then((response) => {
        this.allTerms = response.data.data;
        this.$vs.loading.close();
      }).catch((ex) => {
        this.$vs.loading.close();
      });
    },

    setNotificationEmail(data) {
      this.notificationEmail = data;
    },

    maxPercentageError(err) {
      this.showExtraConditionError = true;
      this.scrollToElement();
    },

    handleChangeDisbursementReference(value) {
      this.disbursementReference = value;
    },

    handleChangeDebitReference(value) {
      this.debitReference = value;
    },

    auto_grow(elem) {
      let element = document.getElementById(elem);

      if (element) {
        element.style.height = "5px";
        element.style.height = (element.scrollHeight) + "px";
      }
    },

    scrollToElement() {
      const el = this.$refs.amount;
      if (el) {
        el.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
      }
    },

    addZeroes(num) {
      num = String(num);
      num.split(".")[1];
      return Number(num).toLocaleString(undefined, { minimumFractionDigits: 2 });
    },

    changeCustomFieldArray(value) {
      this.customFieldsArray[value.key].selectedFile = "";
    },

    async getRequestTemplateDetailById() {
      this.fetchRequestTemplateForRequestForm(this.reqTemplateId).then(async (res) => {
        this.requestTemplate = res.data.data[0];
        this.customTextData = res.data.data[0].customText;
        this.customerData.customerMessage = this.customTextData && this.customTextData.body ? this.customTextData.body : "";

        if (this.requestTemplate.remittanceAdvice) {
          this.defaultRemittanceAdvice = {
            ...this.requestTemplate.remittanceAdvice,
            emailAddress: this.requestTemplate.remittanceAdvice.emailAddress.map((item) => { return { email: item }})
          };
          this.templateRemittanceAdvice = { ...this.defaultRemittanceAdvice };
        }

        if (this.requestTemplate.notifications && this.requestTemplate.notifications.multipleEmailAddress) {
          this.notificationEmail = this.requestTemplate.notifications.multipleEmailAddress;
          this.defaultNotificationEmails = JSON.parse(JSON.stringify(this.requestTemplate.notifications.multipleEmailAddress));
        }

        this.notificationBind = true;

        if (this.requestTemplate.remittanceAdvice) {
          this.remittanceBind = true;
        }

        this.redirectData = res.data.data[0].redirect;

        if (this.requestTemplate.paymentOptions[0].requestOptions.length == 1 && this.requestTemplate.paymentOptions[0].requestOptions.includes("pay-now")) {
          this.hasPayNow = true;
        }

        if (this.requestTemplate.paymentOptions[0].requestOptions.includes("pay-later")) {
          this.hasPayLater = true;
        }

        if (this.requestTemplate.paymentOptions[0].requestType == "recurring") {
          this.isRecurring = true;
          this.$refs.customerDetails.maxHeight = "none !important";
        }

        if (this.requestTemplate.paymentOptions[0].requestType == "one-off") {
          this.isRecurring = false;
        }

        if (this.requestTemplate.customFields.length == 0) {
          this.isCustomFieldEmpty = true;
        } else {
          this.defaultCustomFields = this.requestTemplate.customFields;
          this.customFieldsArray = _.filter(
            this.defaultCustomFields,
            function (o) {
              return o.reqToSendRequest || o.editableByMerchant;
            }
          );
        }

        this.planId = this.requestTemplate.paymentOptions[0].payLaterPlan ? this.requestTemplate.paymentOptions[0].payLaterPlan[0] : "";

        if (this.requestTemplate.paymentOptions[0].planDisplayName) {
          this.planDisplayName = this.requestTemplate.paymentOptions[0].planDisplayName;
        }

        if (this.requestTemplate.header.isDefaultHeader === false) {
          this.templateHeader = { ...this.requestTemplate.header };
        }

        if (this.requestTemplate.header.hasOwnProperty("useRequestSenderDetails")) {
          this.templateHeader.useRequestSenderDetails = this.requestTemplate.header.useRequestSenderDetails;
        } else {
          this.templateHeader.useRequestSenderDetails = false;
        }

        if (this.templateHeader.useRequestSenderDetails) {
          this.assignSenderDetails();
        }

        this.debit = this.requestTemplate.debit || {};

        if (this.hasPayLater) {
          await this.fetchPlanByCustomFieldId();
        }

        if (this.additionalBind) {
          if (this.$refs.customCollapse) {
            this.$refs.customCollapse.maxHeight = "none !important";
          }
        }

        if (this.requestTemplate.disbursement) {
          this.disbursement = this.requestTemplate.disbursement;

          if (this.disbursement.type == "requestUser") {
            this.disbursement.method = this.disbursement.allowAnyBank ? "manual" : "account";
          } else if (this.disbursement.type != "customer") {
            this.disbursement.method = (this.disbursement.allowExternalAccount || this.disbursement.allowMerchantAccount) ? "account" : "manual";
          }

          this.selectedAccount = this.requestTemplate.selectedAccount;

          if (this.disbursement && (this.disbursement.type == "externalAccount" || this.disbursement.allowExternalAccount)) {
            await this.getSavedExternalAccounts();
          }

          this.disbursementBind = true;
          this.showDisbursementTerms = this.disbursement.allowAnyBank || this.disbursement.allowExternalAccount;
        }

        this.isLoaded = true;
        this.$vs.loading.close();
      }).catch(ex => {
        this.showMessage("Template", ex.response.data.message, "danger", "icon-times");
        this.$router.push({name : "partner-dashboard"});
        this.$vs.loading.close();
      });
    },

    assignSenderDetails() {
      if (this.user.email && this.isValidEmail(this.user.email)) {
        this.templateHeader.companyEmail = this.user.email;
      }

      if (this.user.phoneNumber && this.isValidPhoneNumber(this.user.phoneNumber)) {
        this.templateHeader.phone = this.user.phoneNumber;
      }
    },

    isValidEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },

    isValidPhoneNumber(phoneNumber) {
      const re = /^(^04\(?\d{2}\)?[\s.-]\d{3}[\s.-]\d{3})|(^1300[\s.-]\d{3}[\s.-]\d{3})|(^1800[\s.-]\d{3}[\s.-]\d{3})|(^13[\s.-]\d{2}[\s.-]\d{2}$)|(^02[\s.-]\d{4}[\s.-]\d{4})|(^03[\s.-]\d{4}[\s.-]\d{4})|(^07[\s.-]\d{4}[\s.-]\d{4})|(^08[\s.-]\d{4}[\s.-]\d{4})/;
      return re.test(String(phoneNumber));
    },

    async fetchPlanByCustomFieldId() {
      const payload = {
        merchantId: this.merchantId,
        customPlanId: this.planId,
      }

      await this.getPlanByMerchantAndCustomPlanId(payload).then(async (res) => {
        if (res) {
          const result = res.data.data[0].plan;
          this.selectedPlan = result;
          this.gracePeriod = result.gracePeriod || 0;
          this.defaultReference = (res.data.data && res.data.data[0] && res.data.data[0].referenceOrder && res.data.data[0].referenceOrder.length) ? res.data.data[0].referenceOrder[0].label : "Payment request ID";
          this.merchantDisbursementReference = this.defaultReference;

          if (result.extraConditions.length > 0) {
            this.extraConditions = result.extraConditions ? result.extraConditions : [];
            this.errorMsg = this.extraConditions[0].errorMsg;
          }

          if (result.customFields.length == 0) {
            this.isProductFieldEmpty = true;
          }

          this.activePlan = {
            _id: result._id,
            paymentFrequency: result.paymentFrequency,
            feeModel: result.feeModel,
            msf: res.data.data[0] && res.data.data[0].overrideServiceFee ? res.data.data[0].msf : result.msf,
            minimumMsfFeeAmount: result.minimumMsfFeeAmount || 0,
            noOfPayments: result.noOfPayments,
            maximumAmount: result.maximumAmount,
            minimumAmount: result.minimumAmount,
            customFields: result.customFields,
            productType: result.productType,
            productName: result.productName,
            initialPeriod: result.initialPeriod ? result.initialPeriod : null,
            planDisplayName: this.planDisplayName,
            productText: result.productText,
            isPayNow: result.isPayNow || false,
          };

          this.hasPayNow = this.activePlan.isPayNow;
          this.hasPayLater = true;
          this.productConfigType = result.productConfigType;
          this.defaultProductCustomFields = this.activePlan.customFields;
          this.productCustomFields = _.filter(
            this.defaultProductCustomFields,
            function (o) {
              return o.reqToSendRequest || o.editableByMerchant;
            }
          ).map((row) => ({
            ...row,
            addr: {
              unit_number: "",
              street_number: "",
              street_name: "",
              suburb: "",
              state: "",
              street_type: "",
              postcode: "",
              country: "",
            },
          }));
          this.$refs.customerDetails.maxHeight = "none !important";

          if (["B2C_BNPO", "B2C_BNPO_NC"].includes(result.productType) && result.productConfigType == "TWO_PARTY" && this.reqTemplateId) {
            this.termsDetail = result.legals;
            this.autoDebit = result.autoDebit;
            await this.getLegals();
            this.showTermsCard = true;
          }

          this.paymentPageSetting = {
            amountLabel: result.amountLabel,
            cardTitle: result.cardTitle,
            dateLabel: result.dateLabel,
            disbursementLabel: result.disbursementLabel,
            feeLabel: result.feeLabel,
            message: result.message,
            purchaseAmountLabel: result.purchaseAmountLabel,
            totalAmountLabel: result.totalAmountLabel,
          }

          this.calculatePlan();

          this.isLoaded = true;
          this.additionalBind = true;
          this.$vs.loading.close();
        }
      }).catch((ex) => {
        this.$vs.loading.close();
        this.isProductFieldEmpty = true;
        this.additionalBind = true;

        if (this.$refs.customerDetails) {
          this.$refs.customerDetails.maxHeight = "none !important";
        }
      });
    },

    async calculatePlan() {
      await this.calculateOnPayLater({ planId: this.activePlan._id, amount: this.amount > 0 ? this.amount : 0, msf: this.activePlan.msf }).then((result) => {
        this.transactionSchedule = result.data.data;

        const total = result.data.data.find(item => item.type == "Total");
        const disbursement = result.data.data.find(item => item.type == "Disbursement");
        const schedule = result.data.data.filter(item => item.type == "Payment");

        if (this.activePlan.productType == "B2C_BNPL") {
          this.payLaterSchedule = schedule.map((item) => {
            return {
              label: item.amount,
              value: new Date(item.date),
            };
          });
          this.paymentSchedules = this.payLaterSchedule;
          this.totalNumberOfPayments = this.payLaterSchedule.length;
        }

        this.totalCustomerPayable = total.amount;
        this.merchantAmount = disbursement.amount;
      });

       //for finding week month or day
      if (this.activePlan.paymentFrequency) {
        switch (this.activePlan.paymentFrequency) {
          case "DAILY":
            this.weekOrMonthOrDay = "Day";
            break;
          case "FORTNIGHTLY":
            this.weekOrMonthOrDay = "fortnight";
            break;
          case "MONTHLY":
            this.weekOrMonthOrDay = "Month";
            break;
          case "WEEKLY":
            this.weekOrMonthOrDay = "Week";
            break;
        }
      }

      if (this.selectedPlan.productConfigType == "TWO_PARTY") {
        this.showSummaryCard = true;

        if (this.$refs.summary) {
          this.$refs.summary.getPaylaterSchedule();
        }
      }
    },

    async openPopUp(requestType) {
      this.schedulePopup = true;

      if (requestType == "paylater") {
        await this.calculatePlan();
      } else if (requestType == "recurring") {
        this.prepareSchedule();
      }
    },

    prepareSchedule() {
      const startDateWithDay = new Date(this.frequency.startDate);
      let rule = { dtstart: this.frequency.startDate ? startDateWithDay : new Date() };

      if (this.frequency.period === "week") {
        rule.freq = RRule.WEEKLY;
        rule.interval = this.frequency.frequency ? parseInt(this.frequency.frequency) : 0;
      } else {
        rule.freq = RRule.MONTHLY;
        rule.interval = this.frequency.frequency ? parseInt(this.frequency.frequency) : 0;
      }

      if (this.frequency.endType === "by") {
        const endDateWithDay = new Date(this.frequency.endDate);
        rule.until = this.frequency.endDate ? endDateWithDay : new Date();
      } else {
        rule.count = this.frequency.endPayments ? parseInt(this.frequency.endPayments) : 1;
      }
      this.installment(rule);
    },

    installment(payload) {
      const rule = new RRule(payload);
      this.paymentList(rule.all());
    },

    paymentList(schedule) {
      let list = [];
      for (let i = 0; i < schedule.length; i++) {
        list.push({
          label: this.amount,
          value: schedule[i],
        });
      }
      this.totalNumberOfPayments = schedule.length;

      this.paymentSchedules = list;
    },

    moment(date) {
      return moment(date).format("D-MMM-YY");
    },

    formatScheduleDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },

    getWeekDay(date) {
      return moment(date).format("dddd");
    },

    //! send payment request
    async sendPR() {
      try {
        if (this.amount <= 0.00) {
          this.errors.add({
            field: 'amount',
            msg: "Amount must be greater than 0.00"
          })
          return;
        }

        this.isSubmitted = true;
        let customValid = true;
        let productValid = true;
        let customerValid = true;
        let termsValid = true;
        const valid = await this.$validator.validate();

        if (this.$refs.customerFields) {
          customerValid = await this.$refs.customerFields.validateChildForm();
        }

        if (this.$refs.productFields) {
          productValid = await this.$refs.productFields.validateChildForm();
        }

        if (this.$refs.customFields) {
          customValid = await this.$refs.customFields.validateChildForm();
        }

        if (this.$refs.customerDetails) {
          this.$refs.customerDetails.maxHeight = "none !important";
        }

        if (this.$refs.termsValidation) {
          termsValid = await this.$refs.termsValidation.validateChildForm();
        }

        if (this.customerData.sendComms && this.$refs.customerFields && this.customerData.customerName == "") {
          this.isSubmitted = false;
          return false;
        }

        if (this.customerData.sendComms && this.$refs.customerFields && (this.customerData.customerEmail == "") && (this.customerData.customerMobile == "")) {
          this.isSubmitted = false;
          return false;
        }

        if (this.errors.items.length > 0) {
          if (this.$refs.customerDetails) {
            this.$refs.customerDetails.maxHeight = "none !important";
          }
          if (this.$refs.customCollapse) {
            this.$refs.customCollapse.maxHeight = "none !important";
          }
          if (this.$refs.remittanceAdvice) {
            this.$refs.remittanceAdvice.maxHeight = "none !important";
          }

          this.isSubmitted = false;
          return false;
        }

        if (["B2C_BNPO", "B2C_BNPO_NC"].includes(this.activePlan.productType) && this.productConfigType == "TWO_PARTY" && this.reqTemplateId) {
          if (this.autoDebit && (!this.paymentMethodTerms || !Object.keys(this.paymentMethodTerms).length)) {
            termsValid = false;
            this.errors.add({ field: "directDebitTerm", msg: "The Direct debit terms and conditions field is required" });
          }

          if (!this.terms || !Object.keys(this.terms).length) {
            termsValid = false;
            this.errors.add({ field: "productTerm", msg: "The Terms and Conditions field is required" });
          }
        }

        if (!valid || !customValid || !productValid || !customerValid || !termsValid) {
          return false;
        } else {
          const formData = new FormData();
          let selectedBankId = null;

          if (this.user && this.user.adminId && this.user.loginBy) {
            formData.append("createdByAdmin", this.user.adminId);
          }

          for (let i = 0; i < this.files.length; i++) {
            formData.append("file", this.files[i]);
          }

          formData.append("amount", this.amount);
          formData.append("reference", this.reference);
          formData.append("payeeName", this.customerData.customerName);
          formData.append("payeeEmail", this.customerData.customerEmail);
          formData.append("payeePhone", this.customerData.customerMobile);
          formData.append("message", this.customerData.customerMessage);
          formData.append("gracePeriod", this.gracePeriod);

          const customerDetail = {
            customerName: this.customerData.customerName,
            customerMobile: this.customerData.customerMobile,
            customerEmail: this.customerData.customerEmail,
            sendComms: this.customerData.sendComms,
          };

          formData.append("customerDetail", JSON.stringify(customerDetail));
          formData.append("notificationEmail", JSON.stringify(this.notificationEmail));

          if (this.defaultRemittanceAdvice && this.defaultRemittanceAdvice.emailAddress && this.defaultRemittanceAdvice.emailAddress.length) {
            const emails = this.defaultRemittanceAdvice.emailAddress.map(item => item.email);
            formData.append("remittanceAdviceEmail", JSON.stringify(emails));
          } else if (this.requestTemplate.remittanceAdvice && !this.requestTemplate.remittanceAdvice.bankAccountDefault) {
            const emails = this.requestTemplate.remittanceAdvice.emailAddress.map(item => item.email);
            formData.append("remittanceAdviceEmail", JSON.stringify(emails));
          }

          if (!this.reqTemplateId && this.franchisorRemittanceEmail && this.franchisorRemittanceEmail.length) {
            formData.append("remittanceAdviceEmail", JSON.stringify(this.franchisorRemittanceEmail));
          }

          if (this.showTermsCard) {
            formData.append("terms", this.terms);
            formData.append("privacy", this.privacy);

            if (this.autoDebit) {
              formData.append("paymentMethodTerms", this.paymentMethodTerms);
            }
          }

          if (this.requestTemplate && Object.keys(this.requestTemplate).length) {
            formData.append("paymentRequestTemplate", this.requestTemplate._id);

            if (this.requestTemplate.header) {
              formData.append("header", JSON.stringify(this.templateHeader));
            }

            if (this.hasPayLater && this.disbursement && this.disbursement.method) {
              if (this.disbursement.method == "account") {
                selectedBankId = this.selectedLinkedAccount.bankId;
                formData.append("bankId", this.selectedLinkedAccount.bankId);
                formData.append("refModel", this.selectedLinkedAccount.type);
              } else if (this.disbursement.method == "manual") {
                if (this.disabledDisbursement && ["externalAccount", "merchantAccount"].includes(this.disbursement.type) && this.requestTemplate.linkedAccount && this.requestTemplate.linkedAccount.bankId) {
                  selectedBankId = this.requestTemplate.linkedAccount.bankId;
                  formData.append("bankId", this.requestTemplate.linkedAccount.bankId);
                  formData.append("refModel", this.requestTemplate.refModel);
                } else {
                  formData.append("manualDisbursement", JSON.stringify(this.manualDisbursement));
                }
              }
            } else if (this.requestTemplate.linkedAccount && this.requestTemplate.linkedAccount.bankId) {
              selectedBankId = this.requestTemplate.linkedAccount.bankId;
              formData.append("bankId", this.requestTemplate.linkedAccount.bankId);
              formData.append("refModel", this.requestTemplate.refModel);
            }


            if (this.showDisbursementTerms) {
              formData.append("disbursementTerms", this.disbursementTerms);
            }

            formData.append("requestType", this.requestTemplate.paymentOptions[0].requestType);

            if (this.requestTemplate.header.companyLogo) {
              formData.append("companyLogo", this.requestTemplate.header.companyLogo.logoImage);
            }

            if (this.requestTemplate.requestExpiry) {
              formData.append("paymentExpiry", this.requestTemplate.requestExpiry.paymentExpiry);
            }

            if (this.requestTemplate.paymentOptions[0].payLaterPlan) {
              formData.append("plans", JSON.stringify(this.requestTemplate.paymentOptions[0].payLaterPlan));
            }

            if (this.requestTemplate.paymentOptions[0].requestType == "recurring") {
              const frequency = {
                every: this.frequency.period,
                interval: this.frequency.frequency,
                startDate: this.frequency.startDate,
                endType: this.frequency.endType,
                endDate: this.frequency.endDate ? this.frequency.endDate : "",
                endAfterNumberOfPayments: this.frequency.endPayments ? this.frequency.endPayments : "",
              };
              formData.append("frequency", JSON.stringify(frequency));
            }

            if (this.requestTemplate.notifications.prNotification) {
              let prStatus = {};
              this.requestTemplate.notifications.prNotification.map((item) => {
                let status = this.checkNotification(item);
                prStatus[status] = item.forEmail;
              });
              formData.append("prStatus", JSON.stringify({ ...prStatus }));
            }

            if (this.requestTemplate.notifications.txNotification) {
              let txStatus = {};
              this.requestTemplate.notifications.txNotification.map((item) => {
                let status = this.checkNotification(item);
                txStatus[status] = item.forEmail;
              });
              formData.append("txStatus", JSON.stringify({ ...txStatus }));
            }

            formData.append("partnerId", this.requestTemplate.merchantId);
          } else {
            formData.append("requestType", "one-off");
            formData.append("plans", JSON.stringify([this.planId]));
            formData.append("bankId", this.bankId);
            formData.append("franchisorId", this.partnerId);
            formData.append("partnerId", this.merchantId);
          }

          if (this.redirectData && Object.keys(this.redirectData).length) {
            let paymentPage = {
              payLaterRedirect: {
                successButtonText: this.redirectData.success.btntext,
                successUrl: this.redirectData.success.link,
                successBody: this.redirectData.success.message,
                successHeading: this.redirectData.success.heading,
                failureUrl: this.redirectData.fail.link,
                failureButtonText: this.redirectData.fail.btntext,
                failureBody: this.redirectData.fail.message,
                failureHeading: this.redirectData.fail.heading,
              },
              accountId: this.requestTemplate.linkedAccount && this.requestTemplate.linkedAccount.connectorId ? this.requestTemplate.linkedAccount.connectorId : "",
              bankId: selectedBankId || ""
            };

            formData.append("paymentPage", JSON.stringify(paymentPage));

            if (this.redirectData.success && this.redirectData.success.enableRedirect) {
              formData.append("successUrl", this.redirectData.success.link);
              formData.append("successButtonText", this.redirectData.success.btntext);
              formData.append("successDisplayText", this.redirectData.success.btntext);
              formData.append("successHeading", this.redirectData.success.heading);
              formData.append("successBody", this.redirectData.success.message);
            }

            if (this.redirectData.fail && this.redirectData.fail.enablepaynow) {
              formData.append("failureUrl", this.redirectData.fail.link);
              formData.append("failureButtonText", this.redirectData.fail.btntext);
              formData.append("failureDisplayText", this.redirectData.fail.btntext);
              formData.append("failureHeading", this.redirectData.fail.heading);
              formData.append("failureBody", this.redirectData.fail.message);
            }
          }

          let requestOptionsArray = [];

          if (this.hasPayNow) {
            requestOptionsArray.push("pay-now");
          }

          if (this.hasPayLater && (this.amount >= this.activePlan.minimumAmount && this.amount <= this.activePlan.maximumAmount)) {
            requestOptionsArray.push("pay-later");
          }

          formData.append("requestOptions", JSON.stringify(requestOptionsArray));

          if (this.defaultCustomFields.length > 0) {
            let field = this.defaultCustomFields.map((item) => {
              let defaultField = this.customFieldsArray.filter((filterItem) => {
                return item._id == filterItem._id;
              });

              if (item.type==='date') {
                item.value = item.value ? moment(item.value).format("YYYY-MM-DD") : "";
              }

              return {
                label: item.label,
                type: item.type,
                value: defaultField.length > 0 && defaultField[0].value ? defaultField[0].value : "",
                displayToCustomer: item.displayToCustomer,
                editableByCustomer: item.editableByCustomer,
                requiredToSubmit: item.requiredToSubmit,
                editableByMerchant: item.editableByMerchant,
                reqToSendRequest: item.reqToSendRequest,
                customFieldId: item.customFieldId,
                dropdownSelectFields: item.dropdownSelectFields ? item.dropdownSelectFields : [],
              };
            });

            formData.append("customFields", JSON.stringify(field));

            const fileInCustomFields = this.defaultCustomFields.filter(
              (field) => {
                return (field.type === "file" && field.hasOwnProperty("selectedFile"));
              }
            );

            if (fileInCustomFields.length > 0) {
              for (let index = 0; index < fileInCustomFields.length; index++) {
                formData.append("customFiles", fileInCustomFields[index].selectedFile, `${fileInCustomFields[index].label.split(" ").join("-").split("/").join("-")}@@${fileInCustomFields[index].selectedFile.name}`);
              }
            }
          }

          if (this.defaultProductCustomFields.length > 0) {
            let productField = this.defaultProductCustomFields.map((item) => {
              let defaultField = this.productCustomFields.filter((filterItem) => { return item._id == filterItem._id; });
              let fieldValue = "";

              if (defaultField.length > 0) {
                if (item.type == "address") {
                  fieldValue = defaultField[0].addr.hasOwnProperty("street_name") && defaultField[0].addr.street_name.length > 0 ? JSON.stringify(defaultField[0].addr) : "";
                } else if (item.type == "date") {
                  fieldValue = defaultField[0].value ? moment(defaultField[0].value).format("YYYY-MM-DD") : "";
                } else {
                  fieldValue = defaultField[0].value ? defaultField[0].value : "";
                }
              }

              return {
                label: item.label,
                type: item.type,
                value: fieldValue,
                displayToCustomer: item.displayToCustomer,
                editableByCustomer: item.editableByCustomer,
                requiredToSubmit: item.requiredToSubmit,
                editableByMerchant: item.editableByMerchant,
                reqToSendRequest: item.reqToSendRequest,
                displayToCustomerAfter: item.displayToCustomerAfter,
                editableByCustomerAfter: item.editableByCustomerAfter,
                displayToMerchantAfter: item.displayToMerchantAfter,
                editableByMerchantAfter: item.editableByMerchantAfter,
                customFieldId: item.customFieldId,
                dropdownSelectFields: item.dropdownSelectFields ? item.dropdownSelectFields : [],
                validationWhenSendingRequests: item.validationWhenSendingRequests ? item.validationWhenSendingRequests : [],
              };
            });

            formData.append("productCustomFields", JSON.stringify(productField));
            const fileInProductFields = this.productCustomFields.filter((field) => { return (field.type === "file" && field.hasOwnProperty("selectedFile")); });
            for (let index = 0; index < fileInProductFields.length; index++) {
              formData.append("productFiles", fileInProductFields[index].selectedFile, `${fileInProductFields[index].label.split(" ").join("-").split("/").join("-")}@@${fileInProductFields[index].selectedFile.name}`);
            }
          }

          if (this.displayDebit && this.debit && this.debit.bankId) {
            formData.append("debitBankId", this.debit.bankId);
          }

          let reference = this.disbursementReference;
          const referenceList = ["External ID (from HPP)", "Payment request ID", "A product field"]

          if (!reference || reference == "") {
            if ((this.merchantDisbursementReference !== "") && !referenceList.includes(this.merchantDisbursementReference)) {
              reference = this.merchantDisbursementReference;
            } else {
              reference = "";
            }
          }

          formData.append("disbursementReference", reference);
          if (this.debitReference) {
            formData.append("debitReference", this.debitReference);
          }

          formData.append("allowDisbursementDateOverride", this.allowDisbursementDateOverride);

          if (this.allowDisbursementDateOverride) {
            formData.append("disbursementDate", this.disbursementDate ? moment(this.disbursementDate).format("YYYY-MM-DD") : "");
          }

          let obj = {
            obj: formData,
            config: {
              header: {
                "Content-Type": "application/json",
              },
            },
          };
          this.showWarning = false;
          this.$vs.loading();

          await this.storeNewPaymentRequest(obj).then((c) => {
            this.$vs.loading.close();
            this.popupActive = false;
            this.showMessage("Success", "Payment request created successfully.", "success", "icon-check-circle");
            this.to = "partner-dashboard";
            this.isSaved = true;
            this.$router.push({ name: "partner-dashboard" });
          }).catch((ex) => {
            this.$vs.loading.close();

            if (ex.data && ex.data.message && Array.isArray(ex.data.message)) {
              this.productFieldErrors = ex.data.message;
            } else {
              this.showMessage("Error", ex.data.message, "danger", "icon-times");
            }
          });
        }
      } catch (ex) {
        this.$vs.loading.close();
      }
    },

    checkNotification(notify) {
      let statusLabel = "";
      if (notify.status == "Active") {
        statusLabel = "active";
      } else if (notify.status == "Expired") {
        statusLabel = "expired";
      } else if (notify.status == "Cancelled") {
        statusLabel = "cancelled";
      } else if (notify.status == "Complete") {
        statusLabel = "complete";
      } else if (notify.status == "Success") {
        statusLabel = "success";
      } else if (notify.status == "Fail") {
        statusLabel = "fail";
      } else if (notify.status == "Schedule registered") {
        statusLabel = "scheduleRegistered";
      } else if (notify.status == "Schedule registration failed") {
        statusLabel = "scheduleFailed";
      } else if (notify.status == "ID verification failed") {
        statusLabel = "idFailed";
      }

      return statusLabel;
    },

    showMessage(title, message, color, icon) {
      this.$vs.notify({
        title: title,
        text: message,
        color: color,
        iconPack: "feather",
        position: "top-right",
        icon: icon,
      });
    },

    onCancel() {
      this.$router.push({ name: "partner-dashboard" });
    },

    fileUpdate(files) {
      this.files = files;
      this.checkFormDirty = true;
    },

    checkForm() {
      this.checkFormDirty = true;
    },

    termsCallBack(data) {
      this.errors.clear();

      if (data.val) {
        if (data.terms) {
          this.terms = data.terms;
          this.privacy = data.privacy;
        }

        if (data.directDebit) {
          this.paymentMethodTerms = data.termsId;
        }
      } else {
        this.errors.add({
          field: "productTerm",
          message: "Terms and Conditions are required."
        });
      }
    },

    async getGlobalSetting() {
      await this.fetchSettingsByMerchantId().then(async (res) => {
        if (res.data.data.companyHeader) {
          this.templateHeader = { ...res.data.data.companyHeader };
          this.templateHeader.isDefaultHeader = true;
        }
      });
    },

    async checkAdminLogin() {
      this.$vs.loading();
      await this.checkLogin().then(result => {
        this.defaultReference = result.data.data && result.data.data.referenceOrder && result.data.data.referenceOrder.length ? result.data.data.referenceOrder[0].label : "Payment request ID";
        this.merchantDisbursementReference = this.defaultReference;

        if (this.$route.params.partnerId) {
          const franchisees = result.data.data && result.data.data.franchisees ? result.data.data.franchisees : [];

          if (franchisees && franchisees.length) {
            const merchant = franchisees.find(item => item.partnerId == this.$route.params.partnerId);

            if (merchant) {
              this.$emit("changeRouteTitle", `Send request to ${merchant.companyName}`);
              this.customerData.customerName = merchant.companyName;
            }
          }
        }
      });
    },

    showWarningMessage(title = "Warning", message = "Warning", autoClose = false) {
      this.$toast.warning(<div class="container" id="custom-message"><h3>{title}</h3><p>{message}</p></div>, {
        position: "bottom-right",
        timeout: autoClose ? 3000 : false,
        closeOnClick: false,
        pauseOnFocusLoss: false,
        pauseOnHover: false,
        draggable: false,
        draggablePercent: 0.1,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: {
          iconClass: "material-icons text-warning",
          iconChildren: "info_outline",
          iconTag: "span"
        },
        rtl: false,
        message: message
      });
    },

    closeWarningMessage(id) {
      this.$toast.clear(id);
    },

    async getBankAccounts() {
      await this.fetchBankByPartnerId(this.partnerId).then((response) => {
        let merchantBanks = response.data.data;
        merchantBanks = merchantBanks.filter(item => item.isUsedForSettlement);

        this.bankList = merchantBanks.map((item) => {
          return {
            bankId: item._id,
            accountNumber: item.accountNumber,
            bsb: this.maskBsb(item.bsb),
            displayName: item.displayName ? item.displayName : item.accountName,
            remittanceAdviceEmail: [item.remittanceAdviceEmail],
            value: { bankId: item._id, type: "Bank", email: [item.remittanceAdviceEmail] }
          };
        });
      }).catch((ex) => {
        this.showMessage(
          ex.response.data.title || "Bank",
          ex.response.data.message,
          "danger",
          "icon-check-circle"
        );
      });
    },

    async getSavedExternalAccounts() {
      await this.fetchSavedExternalAccounts(this.partnerId).then((response) => {
        if (response.data.data && response.data.data.length) {
          this.savedExternalAccounts = response.data.data.map((item) => {
            return {
              bankId: item._id,
              accountNumber: item.accountNumber,
              bsb: this.maskBsb(item.bsb),
              displayName: item.accountName,
              remittanceAdviceEmail: item.remittance && item.remittance.length ? item.remittance : [],
              value: { bankId: item._id, type: "MerchantSavedExternalAccount", email: item.remittance && item.remittance.length ? item.remittance : [] }
            };
          });
        }
      }).catch((ex) => {
        this.showMessage(ex.response.data.title || "Saved external bank", ex.response.data.message, "danger", "icon-check-circle");
      });
    },

    changeLinkedAccount(data) {
      this.showDisbursementTerms = true;

      if (data) {
        this.selectedLinkedAccount = data;
        this.defaultRemittanceAdvice = {
          bankAccountDefault: true,
          allowAdd: false,
          allowOverride: false,
          emailAddress: data.email.map((item) => { return { email: item } })
        };
        this.showDisbursementTerms = (this.selectedLinkedAccount.type == "MerchantSavedExternalAccount");
      } else {
        this.defaultRemittanceAdvice = { ...this.templateRemittanceAdvice };
      }

      this.checkForm();
    },

    setDisbursement(data) {
      this.manualDisbursement = data.disbursement;
      this.showDisbursementTerms = !data.disabled;
      this.disabledDisbursement = data.disabled;
      this.checkForm();
    },

    changeDisbursementTerms(value) {
      this.disbursementTerms = value;
    },

    maskBsb(string) {
      if (!string.includes("-")) {
        let arr = string.replace(" ", "").split("");
        arr.splice(3, 0, "-");
        string = arr.join("");
      }

      return string;
    },

    changeDebitAccount(data) {
      this.debit.bankId = data;
    },

    handleDisbursementDate(data) {
      this.allowDisbursementDateOverride = data.allowDisbursementDateOverride;
      this.disbursementDate = data.disbursementDate;
    }
  },

  beforeRouteLeave(to, from, next) {
    if ((this.isFormDirty || this.checkFormDirty) && !this.isSaved) {
      this.nextObj = next;
      this.popupActive = true;
      localStorage.setItem("redirectTo", to.name || undefined);
    } else {
      document.body.classList.remove("payment-request-form-select");
      next();
    }
  },

  async beforeMount() {
    this.checkAdminLogin();
    this.getGlobalSetting();
    this.getBankAccounts();

    if (this.reqTemplateId) {
      await this.getRequestTemplateDetailById();
    } else {
      this.planId = this.$route.params.customPlanId;
      await this.fetchPlanByCustomFieldId();

      if (this.additionalBind) {
        if (this.$refs.customCollapse) {
          this.$refs.customCollapse.maxHeight = "none !important";
        }
      }
    }
  },
  updated() {
    this.auto_grow("custom-textarea");
  },
  watch: {
    customerData: {
      handler: function (after, before) {
        if (this.isLoaded) {
          this.$refs.customerDetails.maxHeight = "none !important";
          this.$refs.requestDetails.maxHeight = "none !important";
        }
      },
      deep: true,
    },

    frequency: {
      handler: function (after, before) {
        if (this.isLoaded) {
          this.$refs.paymentOptions.maxHeight = "none !important";
        }
      },
      deep: true,
    },

    checkFormDirty(latest, previous) {
      if (latest) {
        localStorage.setItem("formFieldUpdated", "yes");
      }
    },

    isFormDirty(latest, previous) {
      if (latest) {
        localStorage.setItem("formFieldUpdated", "yes");
      }
    },

    isSaved(latest, previous) {
      if (!latest) {
        localStorage.setItem("formFieldUpdated", "yes");
      }
    },

    showWarning(newVal) {
      if (newVal && this.hasPayNow && this.hasPayLater) {
        this.showWarningMessage("Pay Later can't be offered", "Pay Later is not available for the request amount. If you choose to proceed, the payment request will be sent requesting the customer to Pay Now.");
      } else {
        this.closeWarningMessage("custom-message");
      }
    },

    amount :_.debounce(async function (newVal) {
      if (this.hasPayLater) {
        await this.calculatePlan();
        return this.showWarning = newVal && newVal > 0 && (newVal < parseFloat(this.activePlan.minimumAmount) || newVal > parseFloat(this.activePlan.maximumAmount));
      }
    }, 600),

    reference :_.debounce(async function (newVal) {
      if (this.defaultReference == "Merchant reference") {
        this.merchantDisbursementReference = newVal || this.defaultReference;
      }
    }, 600),
  },
};
</script>

